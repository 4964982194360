import { quantileSeq } from 'mathjs';

import { defaultVariables } from '../constants';

export const getExperimentsRequestParams = (search) => {
  const params = search.slice(1, search.length).split('&')
    .map((param) => {
      const [ name, value ] = param.split('=');
      return { name, value };
    })
    .filter((param) => !!param.value);
      
  let machines = params.find(({ name }) => name === 'machines');

  machines = machines ? decodeURIComponent( machines.value.replace(/\+/g, '%20') )?.split('.') : [];

  let page = params.find(({ name }) => name === 'page');
  page = page ? page.value : 1;

  let variables = [];
  if (params.find(({ name }) => name === 'idsFields')) {
    variables = params
      .find(({ name }) => name === 'idsFields').value
      .split(/\+|%2B/)
      .map(item => item.replace(/\./g, '/'))
      .map(item => ({ idsField: item }));
    variables.unshift({ idsField: 'ids_properties/creation_date' });
  }
  else {
    variables = defaultVariables;
  }

  const conditions = params
    .filter(({ name }) => name !== 'machines' && name !== 'page' && name !== 'idsFields')
    .map(({ name, value }) => {
      if (!name.startsWith('creation_date')) {
        if (!name.includes('min') && !name.includes('max')) {
          return {
            idsField: name.replace(/\./g, '/'),
            condition: '=',
            value
          };
        }
        else {
          const varName = name.slice(0, name.length - 4);
          const condition = name.slice(-3);
  
          return {
            idsField: varName.replace(/\./g, '/'),
            condition: condition === 'min' ? '>=' : '<=',
            value
          };
        }
      }
      else {
        const condition = name.slice(-3);
        
        return {
          idsField: `ids_properties/creation_date`,
          condition: condition === 'min' ? '>=' : '<=',
          value: value.replace(/-/g, '')
        };
      }
    });

  return {
    conditions,
    machines,
    page,
    variables
  };
};

export const formatDate = (dateStr) => {
  return ( dateStr ? `${dateStr.slice(0,4)}-${dateStr.slice(4,6)}-${dateStr.slice(6,8)}` : "" );
};

export const getXAxisTicks = (data) => {
  data = data && data.length ? data : [ { time: 0 } ];
  const time = data.map(val => val.time);
  const minTime = Math.min(...time);
  const maxTime = Math.max(...time);

  if (maxTime - minTime > 1) {
    if (maxTime - minTime <= 6) {
      const ticks = [];
      
      for (let i = Math.floor(minTime); i <= Math.ceil(maxTime); i++) {
        ticks.push(i);
      }
      return ticks;
    }
    else {
      const ticks = [];

      for (let i = Math.floor(minTime); i <= Math.ceil(maxTime); i += (Math.floor((maxTime - minTime)/8) || 1)) {
        ticks.push(i);
      }
      return ticks;
    }
  }

  return quantileSeq(time, [ 0, 1 / 4, 1 / 2, 3 / 4, 1 ]).map((val) => {
    return val.toFixed(2);
  });
};

export const range = (from, to) => {
  let i = from;
  const range = [];

  while (i < to) {
    range.push(i);
    i++;
  }

  return range;
};

export const getFileName = (idsField, shot, run, machine) => {
  return `${shot}_${run}_${machine}_${idsField.replace(/\//g, '_')}`;
};

export const getExperimentInfoFromUri = (uri) => {
  const params = uri.split('?')[1].split(';');
  const obj = {};

  params.forEach(param => {
    const [ name, value ] = param.split('=');
    obj[name] = value;
  });

  return obj;
};

export const getEntryDataRequestParams = (search) => {
  const params = new URLSearchParams(search);
  let filters = [];
  let page = 1;

  if (params.has('name')) {
    filters = [ { idsField: params.get('name') } ];
  }

  if (params.has('page')) {
    page = Number(params.get('page'));
  }

  return {
    filters,
    page
  };
};

export const filterChartDataByIdsField = (chartData, fieldName) => {
  if (chartData.data.length === 0) {
    return null;
  }
  return chartData.data.filter(({ idsField }) => idsField === fieldName)[0];
};

export const getChartTitle = (shot, run, machine, idsField) => {
  return `Shot: ${shot} Run: ${run} Machine: ${machine}, Variable: ${idsField}`;
};

export const getInputType = (type) => {
  if ([ 'double', 'integer' ].includes(type)) {
    return 'number';
  }
  return 'text';
};

export const getFormControls = (idsFields, variablesTypes, allVariables) => {
  return idsFields.map((field) => {
    const variable = allVariables.find(({ idsField }) => idsField === field);
    const variableType = variablesTypes.find(({ id }) => id === variable.variableTypeId);
    
    return {
      type: getInputType(variableType.value),
      ...variable
    };
  });
};

export const getIdsFieldsFromParams = (params) => {
  if (params && params.has('idsFields')) {
    return params.get('idsFields').replace(/\./g, '/').split(/\+|\s/);
  }
  return [];
};

export const getFiltersFromParams = (params) => {
  return Array.from(params.keys())
    .filter(key => key !== 'page' && key !== 'idsFields' && key !== 'machines')
    .map(key => ({
      [key.replace(/\./g, '/')]: params.get(key)
    }))
    .reduce((a, v) => ({ ...a, ...v }), {});
};

export const getUrlParamsFromFormControls = (formControls) => 
  formControls.map(({ idsField }) => idsField.replace(/\//g, '.')).join('+');

export const getMachinesFromParams = (params) => {
  if (params.has('machines') && params.get('machines') !== '') {
    return params.get('machines').split('.').map(name => ({
      label: name,
      value: name
    }));
  }
  return [];
};

export const getValuesFromLabel = (activeLabel) => {
  const [ min, max ] = activeLabel.slice(1, activeLabel.length - 1).split(', ');
  return { min, max };
};

export const getVariableNameFromIdsField = (variables, idsField) => {
  return variables?.data?.find((variable) => variable.idsField === idsField)?.variableName;
};

export const getVariableUnitFromIdsField = (variables, idsField) => {
  return variables?.data?.find((variable) => variable.idsField === idsField)?.unit;
};

const engineeringFormat = new Intl.NumberFormat('en', { notation: 'engineering' });

export const getNumberInFormat = (value, notation = 'engineering') => {
  if (value !== '' && notation === 'engineering') return engineeringFormat.format(value)?.replace(/E0$/, '');

  return value;
};
export const getTimeInFormat = (value) => {
  return (""+value).substring(0,6);
};

